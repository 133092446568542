import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import ToastPlugin from 'vue-toast-notification';

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faArrowLeft, faBars, faCow, faCreditCard, faDrumstickBite, faEnvelope, faFishFins, faHome, faIceCream, faLeaf, faPiggyBank, faQrcode, faRightFromBracket, faUtensils } from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faBars, faArrowLeft, faRightFromBracket, faCreditCard, faEnvelope, faLeaf, faQrcode, faPiggyBank, faCow,faFishFins,faUtensils, faDrumstickBite, faIceCream)

let app=createApp(App)
app.config.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false

app.component("font-awesome-icon", FontAwesomeIcon).use(router).use(ToastPlugin).mount("#app");// main.js
