<template>
  <div class="w-full h-full flex flex-row">
    <div
      v-if="toast"
      class=" rounded-lg text-white font-bold px-6 py-3 w-56 fixed bottom-5 right-5 z-50 transition-transform transform translate-y-0" :class="toastSuccess?'bg-green-600':'bg-red-500'"
    >
      {{ toast }}
    </div>
    <Navigation :title="route" ref="nav" />
    <div class="flex flex-grow flex-col relative">
      <Header :title="route" @toggleMenu="toggleMenu" />
      <div id="content" class="w-full h-full overflow-y-auto">
        <div class="w-full h-full p-4">
          <Cards v-if="route === 'Home' || route === 'Karten'" />
          <Mealplanning v-if="route === 'Essensplanung'" @toast="showToast" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Cards from "@/components/Cards.vue";
import Header from "@/components/Header.vue";
import Navigation from "@/components/Navigation.vue";
import Mealplanning from "@/components/Mealplanning.vue";

export default {
  name: "HomeView",
  components: {
    Navigation,
    Header,
    Cards,
    Mealplanning,
  },
  data() {
    return {
      toast: "",
      route: this.$router.currentRoute.value.name,
    };
  },
  methods: {
    showToast(toast, success=true){
      this.toast=toast
      this.toastSuccess=success
      setTimeout(() => {
        this.toast=""
      },3000)
    },
    toggleMenu() {
      this.$refs.nav.toggle(true);
    },
  },
};
</script>
