<template>
<input autofocus class="w-0 fixed" @keydown.enter="send($event.target.value)"/>
  <div
    v-if="displayMessage.status"
    class="w-full h-full text-center absolute z-30"
    :class="bg"
  >
    <div class="w-2/3 inline-block text-5xl mt-3 font-bold">
      <span class="block">{{ displayMessage.status }}</span>
      <div class="w-full inline-block mt-4">
        <div v-if="displayMessage.name" class="flex flex-row text-4xl">
          <span class="w-full text-left flex-1">Name:</span>
          <span class="w-full text-right flex-grow">{{
            displayMessage.name
          }}</span>
        </div>
        <div v-if="displayMessage.class" class="flex flex-row text-4xl">
          <span class="w-full text-left flex-1">Klasse:</span>
          <span class="w-full text-right flex-grow">{{
            displayMessage.class
          }}</span>
        </div>
        <span v-if="displayMessage.lastSeen" class="text-4xl inline-block"
          >Zuletzt registriert:<br />{{ displayMessage.lastSeen }}</span
        >
        <div v-if="displayMessage.main" class="mt-12 bg-white rounded-lg">
          <span class="text-4xl py-4 inline-block">{{displayMessage.main}}</span>
        </div>
        <div v-if="displayMessage.variation" class=" bg-white rounded-lg">
          <span class="text-4xl py-4 inline-block">({{displayMessage.variation}})</span>
        </div>
        <div v-if="displayMessage.dessert" class="mt-12 bg-white rounded-lg">
          <span class="text-4xl py-4 inline-block">{{displayMessage.dessert}}</span>
        </div>
        <div v-if="displayMessage.veggie" class="mt-12 bg-white rounded-lg px-4 pb-4 pt-2 inline-block">
          <font-awesome-icon icon="leaf" class="mx-2 text-4xl inline-block" />
          <span class="text-4xl inline-block">Vegetarisch</span>
          <font-awesome-icon icon="leaf" class="mx-2 text-4xl inline-block" />
        </div>
      </div>
    </div>
  </div>
  <div v-if="mensa" class="w-full h-full text-center">
    <img
      class="inline-block max-w-full max-h-full"
      :src="require('@/assets/mensen/' + mensa + '.png')"
    />
    <div
      v-if="$route.query.monitor === undefined"
      class="absolute bottom-5 left-5 bg-white rounded-lg shadow-lg cursor-pointer p-5 z-10"
      @click="startQRScan()"
    >
      <font-awesome-icon icon="qrcode" class="text-5xl inline-block" /><br />
      <span class="text-xl font-bold">Code Scannen</span>
    </div>
    <div v-show="qrscan" class="absolute w-full h-full top-0 left-0">
      <div class="flex items-center justify-center w-full h-full z-10">
        <video
          id="video"
          ref="video"
          class="w-96 h-96 max-h-96"
          style="-webkit-transform: scaleX(-1); transform: scaleX(-1)"
          autoplay
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API";
export default {
  name: "MensaView",
  data() {
    return {
      lastTag: "",
      lastScan: new Date(),
      rfid: "",
      mensa: this.$router.currentRoute.value.name,
      displayMessage: {},
      bg: "bg-green-300/90",
      qrscan: false,
    };
  },
  methods: {
    openWebSocket() {
      console.log("opening socket");
      const url = `wss://s.rescanner.de/ws/${this.$router.currentRoute.value.name}`;
      this.webSocket = new WebSocket(url);

      this.webSocket.onmessage = (event) => {
        this.webSocketMessage = event.data;
        console.log("WebSocket message:", event.data);
        let msg = JSON.parse(event.data);
        this.handleResponse(msg.data, msg.status);
      };
    },
    send(tag, type = "rfid") {
      API.checkRFID(
        {
          rfid: tag,
          project: this.$router.currentRoute.value.name,
          type: type,
        },
        (r, status) => {
          this.handleResponse(r, status);
        }
      );
    },
    handleResponse(r, status) {
      this.displayMessage = r;
      if (status == 200) {
        this.bg = "bg-green-300/90";
      } else {
        this.bg = "bg-red-300/90";
      }
      setTimeout(() => {
        this.displayMessage = {};
      }, 4000);
    },
    startQRScan() {
      if (this.qrscan) {
        window.rescanner.stopQRScan();
        this.qrscan = false;
        return;
      }
      console.log("start scan");
      window.rescanner.startQRScan(this.$refs.video);
      this.qrscan = true;
    },
    reformatAndSendRFID(tag) {
      // tag = tag.match("[0-9A-z]*")
      // if (tag) tag = tag[0]
      if(tag == this.lastTag) {
        return
      }
      this.lastTag = tag
      let reversedHex = tag
        .replace(/\s/g, "")
        .match(/.{2}/g)
        .reverse()
        .join("");
      let decimalNumber = parseInt(reversedHex, 16).toString();
      if (decimalNumber.length < 10) decimalNumber = "0" + decimalNumber;
      console.log(decimalNumber);
      this.send(decimalNumber);
    },
  },
  mounted() {
    if (this.$route.query.monitor !== undefined) {
      this.openWebSocket();
    }
    if (this.$route.query.rfid) {
      this.rfid = this.$route.query.rfid;
      send();
    }
    if (!window.rescanner) {
      let vm = this;
      let rescannerLib = document.createElement("script");
      rescannerLib.setAttribute("src", "https://cdn.rescanner.de/rescanner.js");
      rescannerLib.onload = () => {
        window.rescanner.onNFCSuccess = (message) => {
          vm.reformatAndSendRFID(message);
        };
        window.rescanner.onQRSuccess = (message) => {
          vm.qrscan = false;
          vm.send(message);
        };
      };
      document.head.appendChild(rescannerLib);
    }
  },
};
</script>