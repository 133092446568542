import QRious from 'qrious'
import { h, ref, watch } from 'vue-demi'
export const LEVELS = ['L', 'M', 'Q', 'H']
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    background: String,
    backgroundAlpha: Number,
    foreground: String,
    foregroundAlpha: Number,
    level: {
      type: String,
      validator: (level) => LEVELS.includes(level)
    },
    mime: String,
    padding: Number,
    size: Number
  },
  setup (props, { attrs }) {
    const qrious = new QRious(props)
    const dataUrlRef = ref(qrious.toDataURL(props.mime))
    watch(props, () => {
      qrious.set(props)
      dataUrlRef.value = qrious.toDataURL(props.mime)
      return () => h('img', Object.assign(Object.assign({}, attrs), { src: dataUrlRef.value }))
    })
    return () => h('img', Object.assign(Object.assign({}, attrs), { src: dataUrlRef.value }))
  }
}
