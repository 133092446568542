import { get, post } from "./lib/http";

function getMailCode(mail, cb) {
    post("/auth/mail/code", { mail: mail, requestId: generateRequestId(mail) }, cb)
}

function usesPasswordLogin(mail, cb){
    post("/auth/usesPasswordLogin", { mail: mail }, cb)
}

function oidcLogin(provider, code, cb) {
    post("/auth/login", { provider: provider, code: code, redirectOrMail: process.env.VUE_APP_FE_URL + "/login" }, cb)
}
function mailCodeLogin(mail, code, cb) {
    post("/auth/login", { provider: "mailcode", code: code, redirectOrMail: mail }, cb)
}
function mailPasswordLogin(mail, password, cb) {
    post("/auth/login", { provider: "mailpassword", password: password, redirectOrMail: mail }, cb)
}
function checkAuth(cb) {
    get("/auth", cb)
}
function getCards(cb) {
    get("/cards", cb)
}
function checkRFID(data, cb) {
    post("/rfidcheck", data, cb, false)
}
function logout(cb) {
    post("/auth/logout", {}, cb)
}

function getMealPlanForWeek(contract, year,cw,cb){
    get("/contracts/"+contract+"/mealplan/"+year+cw,cb)
}

function saveMealPlanForWeek(contract, year,cw,data,cb){
    post("/contracts/"+contract+"/mealplan/"+year+cw,data,cb)
}

function getQRCode(id, cb) {
    get("/cards/" + id+"/qr", cb)
}

function getEatingContracts(cb) {
    get("/mealplan/contracts", cb)
}

export default {
    getMailCode,
    oidcLogin,
    mailCodeLogin,
    checkAuth,
    getCards,
    checkRFID,
    logout,
    usesPasswordLogin,
    mailPasswordLogin,
    getQRCode,
    getMealPlanForWeek,
    getEatingContracts,
    saveMealPlanForWeek
}

function generateRequestId(message) {
    let hashValue = 0
    for (let i = 0; i < message.length; i++) {
        let charCode = message.charCodeAt(i);
        hashValue = ((hashValue << 5) - hashValue) + charCode;
        hashValue = hashValue & hashValue; // Ensure 32-bit integer limit
    }

    hashValue = hashValue >>> 0; // Convert to unsigned 32-bit integer
    console.log(hashValue.toString(16).padStart(8, '0'));
    return hashValue.toString(16).padStart(8, '0');
}